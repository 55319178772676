import React, { useEffect, useState } from "react";
import withStyles from "react-jss";
import { Skeleton } from "@material-ui/lab";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import MapViz from "./MapViz";
import ColorViz from "./ColorViz";
import { filterByGrouping, convertToSlug } from "../../utils/functions";
import { host } from "../../config";
import axios from "axios";

const styles = {
  vizOuterContainer: {
    flex: "0 1 25%",
    marginRight: "30px",
    padding: "0px 10px",
    "@media (max-width: 820px)": { flex: "0 1 40%" },
    "@media (max-width: 580px)": { display: "block" },
  },
  vizContainer: {
    display: "flex",
    flexDirection: "column",
    //border: "1px solid rgb(224,224,224)",
    //padding: "5px 25px",
    "@media (max-width: 580px)": {
      display: "block",
      border: "none",
      padding: "0px",
    },
  },
  flexItem: {
    flex: "1 1 50%",
  },
};

const VizContainer = ({
  classes,
  isMobile,
  tagResults,
  searchTerms,
  searchFilters,
  gallery,
}) => {
  const [mapCity, setMapCity] = useState([]);
  const [mapColor, setMapColor] = useState([]);
  
  const fetchCityColor = (cityArray, colorArray, applyFilters) => {
    if (cityArray.length) {
      let promises = [];
      let mapCityData = [];

      cityArray.map((city) => {
        let filters = [city];
        if (applyFilters) {
          searchTerms.city.map((cityName) => {
            let tempCity = "tags/city/" + convertToSlug(cityName.toLowerCase());
            filters.push(tempCity);
          });
          searchTerms.style.map((colorName) => {
            let tempColor =
              "fashion-tags/color/" + convertToSlug(colorName.toLowerCase());
            filters.push(tempColor);
          });
          searchTerms.item.map((itemName) => {
            let tempItem =
              "fashion-tags/clothing/" + convertToSlug(itemName.toLowerCase());
            filters.push(tempItem);
          });
          searchTerms.season.map((seasonName) => {
            let tempSeason =
              "tags/season/" + convertToSlug(seasonName.toLowerCase());
            filters.push(tempSeason);
          });
        }
        promises.push(
          axios.post(`${host}/city-results`, {
            filters: filters,
            page: 1,
            size: 1,
          })
        );
      });
      Promise.all(promises)
        .then((responses) => {
          responses.map((response) => {
            let temp = {};
            temp.total = response.data.total;
            temp.name = JSON.parse(response.config.data).filters[0].split(
              "/"
            )[2];
            mapCityData.push(temp);
          });
        })
        .then(() => {
          setMapCity(
            mapCityData.filter(function (el) {
              return el.total != 0;
            })
          );
        });
    }

    if (colorArray.length) {
      let colorPromises = [];
      let mapColorData = [];
      colorArray.map((color) => {
        let filters = [color];
        if (applyFilters) {
          searchTerms.city.map((cityName) => {
            let tempCity = "tags/city/" + convertToSlug(cityName.toLowerCase());
            filters.push(tempCity);
          });
          searchTerms.style.map((colorName) => {
            let tempColor =
              "fashion-tags/color/" + convertToSlug(colorName.toLowerCase());
            filters.push(tempColor);
          });
          searchTerms.item.map((itemName) => {
            let tempItem =
              "fashion-tags/clothing/" + convertToSlug(itemName.toLowerCase());
            filters.push(tempItem);
          });
          searchTerms.season.map((seasonName) => {
            let tempSeason =
              "tags/season/" + convertToSlug(seasonName.toLowerCase());
            filters.push(tempSeason);
          });
        }
        colorPromises.push(
          axios.post(`${host}/color-results`, {
            filters: filters,
            page: 1,
            size: 1,
          })
        );
      });
      Promise.all(colorPromises)
        .then((responses) => {
          responses.map((response) => {
            let temp = {};
            temp.total = response.data.total;
            temp.name = JSON.parse(response.config.data).filters[0].split(
              "/"
            )[2];
            mapColorData.push(temp);
          });
        })
        .then(() => {
          setMapColor(
            mapColorData.filter(function (el) {
              return el.total != 0;
            })
          );
        });
    }
  };
  useEffect(() => {
    if (!searchFilters.length) {
      var tagCount = [];
      const tagsWithEnoughResults = tagResults.map((tag) => {
        return {
          ...tag,
          count:
            tagCount && tagCount[tag.tagstring] ? tagCount[tag.tagstring] : 0,
        };
      });

      const cityObject = filterByGrouping(tagsWithEnoughResults, "city");
      const cityArray = cityObject.map((row) => row.tagstring);

      const colorObject = filterByGrouping(tagsWithEnoughResults, "color");
      const colorArray = colorObject.map((row) => row.tagstring);

      fetchCityColor(cityArray, colorArray);
    }
  }, [searchFilters, tagResults]);

  useEffect(() => {
    if (searchFilters.length) {
      // let galleryTags = gallery.map((a) => a.tags);
      // let galleryTagsWithDuplicates = [].concat.apply([], galleryTags);
      // let uniqData = [...new Set(galleryTagsWithDuplicates)];

      // let colorPattern = /color/;
      // let colorArray = uniqData.filter(function (str) {
      //   return colorPattern.test(str);
      // });
      // let cityPattern = /city/;
      // let cityArray = uniqData.filter(function (str) {
      //   return cityPattern.test(str);
      // });

      var tagCount = [];
      const tagsWithEnoughResults = tagResults.map((tag) => {
        return {
          ...tag,
          count:
            tagCount && tagCount[tag.tagstring] ? tagCount[tag.tagstring] : 0,
        };
      });

      const cityObject = filterByGrouping(tagsWithEnoughResults, "city");
      let cityArray = cityObject.map((row) => row.tagstring);

      const colorObject = filterByGrouping(tagsWithEnoughResults, "color");
      let colorArray = colorObject.map((row) => row.tagstring);

      searchTerms.city.map((city) => {
        let filtered = cityArray.filter((name) =>
          name.includes(city.toLowerCase())
        );
        cityArray = filtered;
      });

      fetchCityColor(cityArray, colorArray, true);
    }
  }, [searchFilters && gallery]);

  return (
    <>
      <div className={classes.vizOuterContainer}>
        <div className={classes.vizContainer}>
          <>
            <div className={classes.flexItem}>
              <ErrorBoundary>
                {mapCity !== undefined ? (
                  mapCity.length ? (
                    <MapViz mapCity={mapCity} />
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <Skeleton
                        variant="rect"
                        width={350}
                        height={300}
                        className={classes.image}
                      />
                    </div>
                  )
                ) : (
                  ""
                )}
              </ErrorBoundary>
            </div>
            {isMobile ? null : (
              <div className={classes.flexItem}>
                <ErrorBoundary>
                  {mapColor != undefined ? (
                    mapColor.length ? (
                      <ColorViz mapColor={mapColor} />
                    ) : (
                      <div style={{ marginTop: "20px" }}>
                        <Skeleton
                          variant="rect"
                          width={350}
                          height={300}
                          className={classes.image}
                        />
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </ErrorBoundary>
              </div>
            )}
            <div className={"ad__slot--rail"}></div>
          </>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(VizContainer);
