import React, { useState, useEffect, useCallback } from 'react';
import withStyles from 'react-jss';
import VizContainer from './components/Visualizations/VizContainer';
import Title from './components/Titles/Title';
import Gallery from './components/Gallery/Gallery';
import { mapping } from './utils/csvToJson';
import DropdownMultipleCombobox from './components/Sidebar/Dropdown/DropdownMultipleCombobox';
import DropdownSingleComboBox from './components/Sidebar/Dropdown/DropdownSingleCombobox';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import useWindowSize from './hooks/useWindowSize';
import useDidMountEffect from './hooks/useDidMountEffect';
import { filterByGrouping } from './utils/functions';
import { host } from './config';

var request = require('request');

const styles = {
  app: {
    margin: '70px',
    fontFamily: 'ITCFranklinGothic,helvetica,sans-serif',
    fontSize: '16px',
    '@media (max-width: 580px)': { margin: '56px 0px 0px 0px' },
  },
  input: { width: '100%', height: '44px' },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '0 20% 50px',
    '@media (max-width: 580px)': { margin: '0 10% 50px' },
  },
  decorSpan: {
    width: '100px',
    height: '1.5px',
    background: '#A60505',
    margin: '10px auto',
  },
  introText: {
    fontFamily: 'Savoy',
    color: '#333',
    fontSize: '20px',
  },
  sectionSubTitle: {
    fontFamily: 'Savoy',
  },
  dropdownContainer: {
    margin: '10px',
    display: 'flex',
    alignSelf: 'flex-start',
    '@media (max-width: 580px)': { display: 'block' },
  },
  dropdown: {
    flex: '0 1 25%',
    margin: '0px 0px 0px 0px',
    fontFamily: 'ITCFranklinGothic,helvetica,sans-serif',
    '&:last-child': {
      marginRight: '0px',
    },
    '@media (min-width: 580px)': {
      margin: '0px 20px 0px 0px',
      '&:first-child': {
        marginRight: '20px',
      },
    },
  },
  mainContent: {
    display: 'flex',
    margin: '10px',
    '@media (max-width: 580px)': {
      display: 'block',
    },
  },
  galleryContainer: {
    flex: '1 1 75%',
    '@media (max-width: 820px)': { flex: '0 1 70%' },
  },
  tagPill: {
    backgroundColor: '#ececec',
    borderRadius: '3px',
    margin: '2px',
    padding: '2px 4px',
  },
};

// Make sure photo results contain all search terms
const filteredPhotos = (arr1, arr2) => {
  return arr1.filter((photo) => {
    return arr2.every((v) => photo.tags.includes(v));
  });
};

function App({ classes }) {
  const [masterPhotos, setMasterPhotos] = useState([]); // contains all photos
  const [gallery, setGallery] = useState(masterPhotos); // just the photos that match the selection
  const [tags, setTags] = useState({
    item: [],
    style: [],
    city: [],
    season: [],
  });
  const [tagMap, setTagMap] = useState({}); // maps the long tag name ('tags/city/paris') to slug name ('paris')
  const [tagCount, setTagCount] = useState(null);
  const [tagResults, setTagResults] = useState([
    { slug: '', tagstring: '', grouping: '' },
  ]);
  const [status, setStatus] = useState('loading'); // determines if data is loading or not
  const [sizeApi, setSizeApi] = useState(21);
  const [pageApi, setPageApi] = useState(1);
  const [loadBtn, setLoadBtn] = useState(true);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [dropDownClick, setDropDownClick] = useState(true);
  const [searchTerms, setSearchTerms] = useState({
    item: [],
    style: [],
    city: [],
    season: [],
  });
  const [expandFilters, setExpandFilters] = useState(false);
  const [searchFilters, setSearchFilters] = useState([]);

  const size = useWindowSize();
  const isMobile = size.width < 580;

  // console.log({categoryTags})
  // console.log({ gallery });
  //console.log(masterPhotos);
  // console.log({tagMap})
  // console.log({ searchTerms });

  const fetchData = (filters = [], loader = false) => {
    filters = filters.length ? filters : ['fashion-tags/street-style'];
    request.post(
      {
        url: `${host}/results`,
        json: true,
        body: {
          filters: filters,
          page: pageApi,
          size: sizeApi,
        },
      },
      function (error, response, body) {
        if (error) {
          console.log('error:', error);
        } else {
          const photos = body ? body : [{}];
          let masterPhotosTemp = masterPhotos.concat(photos);
          if (loader) {
            setMasterPhotos(masterPhotosTemp);
            setGallery(masterPhotosTemp);
          } else {
            setMasterPhotos(photos);
            setGallery(photos);
          }
          setLoadBtn(true);
          setStatus('initial');
          !loader && setApiLoaded(true);
          loader && window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  //get a count of all tags in resulting gallery photos
  useEffect(() => {
    const allTags = masterPhotos.map((photo) => {
      return photo.tags;
    });
    var tagCount = [];
    const concatTags = allTags.reduce((a, b) => a.concat(b), []);

    //count occurance of each city
    for (var i = 0; i < concatTags.length; i++) {
      var tag = concatTags[i];
      tagCount[tag] = tagCount[tag] ? tagCount[tag] + 1 : 1;
    }
    // const filtered = tagCount.filter(item[key] <6)

    setTagCount(tagCount);
  }, [masterPhotos]);

  // GETS THE IMAGE OBJECT
  useEffect(() => {
    // request.get(`${host}/api`, function (error, response, body) {
    //   const photos = body ? JSON.parse(body) : [{}];
    //   setMasterPhotos(photos);
    //   setGallery(photos);
    //   setStatus("initial");
    //   if (error) {
    //     console.log("error:", error);
    //   }
    // });
  }, []);

  // GETS THE RESULTS
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchFilters && searchFilters.length) {
      setApiLoaded(false);
      fetchData(searchFilters);
    }
  }, [searchFilters]);

  useDidMountEffect(() => {
    if (searchFilters && searchFilters.length < 1) {
      setApiLoaded(false);
      fetchData(searchFilters);
    }
  }, [dropDownClick]);

  useDidMountEffect(() => {
    setLoadBtn(false);
    fetchData(searchFilters, true);
  }, [pageApi]);

  // GETS THE TAGS
  useEffect(() => {
    request.get(`${host}/tags`, function (error, response, body) {
      let rawTagResultsArray = [];
      let rawTagResults = [];

      rawTagResults = body ? JSON.parse(body) : [{}];
      rawTagResultsArray = JSON.parse(rawTagResults);

      setTagResults(rawTagResultsArray);
      if (error) {
        console.log('error:', error);
      }
    });
  }, []);

  // GETS THE DATE FOR QA
  useEffect(() => {
    request.get(`${host}/date`, function (error, response, body) {
      const date = body ? JSON.parse(body) : [{}];
      if (error) {
        console.log('error:', error);
      }
    });
  }, []);

  // TRANSFORMS TAGS
  useEffect(() => {
    let tagsWithEnoughResults = [];
    tagsWithEnoughResults = tagResults.map((tag) => {
      return {
        ...tag,
        count:
          tagCount && tagCount[tag.tagstring] ? tagCount[tag.tagstring] : 0,
      };
    });
    //.filter((item) => item.count > 1);

    const tagMapping = mapping(tagsWithEnoughResults);

    //console.log({ tagsWithEnoughResults });
    // filter list for city, category and tag
    setTagMap(tagMapping);
    // just the list of slug tags
    // ex: [Blazer, Boots, Belt ...]
    // CITY TAGS
    const cityObject = filterByGrouping(tagsWithEnoughResults, 'city');
    const cityArray = cityObject.map((row) => row.slug);

    // item TAGS
    const itemObject = filterByGrouping(tagsWithEnoughResults, 'item');
    const itemArray = itemObject.map((row) => row.slug);

    // STYLE TAGS
    const styleObject = filterByGrouping(tagsWithEnoughResults, 'style');
    const styleArray = styleObject.map((row) => row.slug);

    // SEASON TAGS
    const seasonObject = filterByGrouping(tagsWithEnoughResults, 'season');
    const seasonArray = seasonObject.map((row) => row.slug);
    setTags({
      season: seasonArray,
      city: cityArray,
      item: itemArray,
      style: styleArray,
    });
  }, [tagCount, tagResults]);

  // actions to run when a user clicks the submit button inlcuding updating state and making the api call
  // useCallback is supposed to stop the infinite rerenders that happen when we pass this function as the callback prop to DropdownMultipleComboBox but that doesnt seem to be working so I removed it from the useEffect dependency array
  const filterGallery = useCallback(
    (input, tagMap, dropdown, masterPhotos) => {
      //console.log('inputs', input);
      // update the search terms with the latest input
      const searchTermsCopy = Object.assign(searchTerms);
      searchTermsCopy[dropdown] = input;
      setSearchTerms(searchTermsCopy);

      // need to have all of the tags in a flat array in order to filter
      const arrayOfSearchTerms = [];
      Object.keys(searchTermsCopy).forEach((section) => {
        for (let i = 0; i < searchTermsCopy[section].length; i++)
          arrayOfSearchTerms.push(tagMap[searchTermsCopy[section][i]]);
      });
      // fire tracking event for selected group
      if (window.parent && window.parent.dataLayer) {
        window.parent.dataLayer.push({
          event: `datavisualization-vogueStreetStyle-${JSON.stringify(
            arrayOfSearchTerms
          )}-selected`,
        });
      }
      // filter the master list of photos and update the gallery state with those photos
      const filtered = filteredPhotos(masterPhotos, arrayOfSearchTerms);

      //setGallery(filtered);

      setSearchFilters(arrayOfSearchTerms);

      //setStatus(arrayOfSearchTerms.length > 0 ? "filtered" : "initial");
    },

    [searchTerms]
  );
  return (
    <div className={classes.app}>
      <div className={classes.header}>
        <div className={'ad__slot--mid-content'}></div>
        <Title>Street Style Trend Tracker</Title>
        <span className={classes.decorSpan}></span>
        <p className={classes.introText}>
          Plaid coats in Paris, animal print on accessories, neon puffers for
          fall. Use our new interactive tool to explore Vogue Runway’s
          ever-expanding archive of street style photos tagged by item, style,
          city, and season. We’ll add new photos all season long, so check back
          often to keep up with what’s trending.
        </p>
      </div>
      <ErrorBoundary>
        <div className={classes.dropdownContainer}>
          <div className={classes.dropdown}>
            <DropdownMultipleCombobox
              items={tags.item}
              label={'Item'}
              callback={(input) => {
                setDropDownClick(!dropDownClick);
                filterGallery(input, tagMap, 'item', masterPhotos);
              }}
            />
          </div>
          <div className={classes.dropdown}>
            <DropdownMultipleCombobox
              items={tags.style}
              label={'Style'}
              callback={(input) => {
                setDropDownClick(!dropDownClick);
                filterGallery(input, tagMap, 'style', masterPhotos);
              }}
            />
          </div>
          {isMobile && !expandFilters ? (
            <p onClick={() => setExpandFilters(true)}>
              + FILTER BY SEASON OR CITY
            </p>
          ) : (
            <>
              <div className={classes.dropdown}>
                <DropdownSingleComboBox
                  items={tags.city}
                  label={'City'}
                  callback={(input) => {
                    setDropDownClick(!dropDownClick);
                    filterGallery(input, tagMap, 'city', masterPhotos);
                  }}
                />
              </div>
              <div className={classes.dropdown}>
                <DropdownSingleComboBox
                  items={tags.season}
                  label={'Season'}
                  callback={(input) => {
                    setDropDownClick(!dropDownClick);
                    filterGallery(input, tagMap, 'season', masterPhotos);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </ErrorBoundary>

      <div className={classes.mainContent}>
        {gallery.length ? (
          <VizContainer
            tagResults={tagResults}
            isMobile={isMobile}
            searchTerms={searchTerms}
            searchFilters={searchFilters}
            gallery={gallery}
          />
        ) : (
          ''
        )}

        <div className={classes.galleryContainer}>
          <ErrorBoundary>
            <Gallery
              photos={gallery}
              columnCount={3}
              tagMap={tagMap}
              status={status}
              apiLoaded={apiLoaded}
              searchTerms={searchTerms}
              sizeApi={sizeApi}
              pageApi={pageApi}
              setSizeApi={setSizeApi}
              setPageApi={setPageApi}
              loadBtn={loadBtn}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(App);
