import React from "react";
import withStyles from "react-jss";
import { useChartDimensions } from "./utils";
import Chart from "./Chart";
import * as d3 from "d3";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const styles = {
  mapContainer: {
    height: "350px",
  },
  subtitle: {
    fontFamily: "Savoy",
    fontSize: "16px",
    lineHeight: "19px",
  },
  vizTitle: {
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    color: "#333",
    textTransform: "uppercase",
  },
  chartContainer: {
    "@media (min-width: 580px)": {
      marginLeft: "-60px",
    },
    "@media (max-width: 380px)": {
      marginLeft: "-100px",
    },
  },
};

function MapViz({ classes, mapCity }) {

  var cities = mapCity.map((item, i) => {
    let city = item.name.toUpperCase().replace(/-/g, " ");
    return {
      city: city,
      count: item.total,
      color: "#333",
    };
  });
  cities.sort((a, b) => b.count - a.count);

  var topCities = [...cities].slice(0, 6);
  const topCity = cities[0] ? cities[0].city : "...";

  // set up chart dimensions
  const [ref, dimensions] = useChartDimensions({
    marginTop: 0,
    marginLeft: 0,
    marginRight: 20,
    height: 250,
    width: 250,
  });

  var pack = () => {
    return d3.pack().size([dimensions.width, dimensions.height]).padding(45);
  };

  var makeheirarachy = (data) => {
    return d3.hierarchy({ children: data }).sum((d) => d.count);
  };

  let heirarchialData = makeheirarachy(topCities);
  let packLayout = pack();
  const root = packLayout(heirarchialData);

  const nodes = root.children;

  const totalValue = topCities
    .map((city) => city.count)
    .reduce((a, b) => a + b, 0);

  const fontScale = d3
    .scaleLinear()
    .domain(d3.extent(topCities, (d) => d.count))
    .range([8, 18]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  if (nodes && nodes.length > 0) {
    return (
      <ErrorBoundary>
        <div className={classes.mapContainer} ref={ref}>
          <p className={classes.vizTitle}>{`Top Tagged City: ${toTitleCase(
            topCity
          )}`}</p>
          <div className={classes.chartContainer}>
            <Chart dimensions={dimensions}>
              {nodes.length !== 1 ? (
                <g transform={`translate(${dimensions.width / 5}, ${0})`}>
                  {nodes.map((node, i) => {
                    let city = node.data.city;
                    if (city === "TOKYO JAPAN") city = "TOKYO";
                    const fontSize = fontScale(node.data.count);
                    const nodeRatio = node.data.count / totalValue;
                    return (
                      <g key={i}>
                        <circle
                          style={{ fill: node.data.color }}
                          strokeWidth="2px"
                          stroke={node.data.color}
                          cx={node.x}
                          cy={node.y}
                          r={node.r ? node.r : 0}
                          opacity={
                            nodeRatio >= 0.25
                              ? 1
                              : nodeRatio < 0.25 && nodeRatio > 0.1
                              ? 0.7
                              : 0.4
                          }
                        ></circle>
                        <text
                          x={node.x}
                          y={node.y}
                          opacity={
                            nodeRatio >= 0.25
                              ? 1
                              : nodeRatio < 0.25 && nodeRatio > 0.1
                              ? 0.7
                              : 0.4
                          }
                          style={{
                            fill: node.data.color,
                            fontWeight: "bold",
                            fontFamily:
                              "ITCFranklinGothic,helvetica,sans-serif",
                            textTransform: "uppercase",
                            fontSize: fontSize,
                          }}
                          dx={
                            node.x && node.r
                              ? node.x < 125 ||
                                (node.x >= 125 &&
                                  node.x < 128 &&
                                  node.y > 115 &&
                                  node.y < 128)
                                ? city.length > 7
                                  ? -1.5 * node.r -
                                    (node.r < 20 ? 15 : node.r > 40 ? 0 : 30)
                                  : -1.2 * node.r
                                : node.y > 190 && node.x < 75
                                ? -1.5 * node.r - 4
                                : node.y < 90
                                ? node.x > 143
                                  ? node.r / 4 - 5
                                  : -1.2 * node.r
                                : node.y > 188 && node.x > 75
                                ? node.r / 4 + 5
                                : node.x > 155 && node.y > 95 && node.y < 145
                                ? city.length > 7
                                  ? -14
                                  : -5
                                : node.r + 5
                              : node.r + 2
                          }
                          dy={
                            node.y && node.r
                              ? node.x < 125 ||
                                (node.x >= 125 &&
                                  node.x < 128 &&
                                  node.y > 115 &&
                                  node.y < 128)
                                ? node.y < 90
                                  ? -1.2 * node.r - 3
                                  : 1.5 * node.r + (node.r < 25 ? 8 : 2)
                                : node.y > 188 && node.x > 75
                                ? node.r + 15
                                : node.y < 90
                                ? -node.r - 8
                                : node.x > 155 && node.y > 95 && node.y < 145
                                ? 1.5 * node.r + 2
                                : node.r / 4
                              : node.r / 4
                          }
                        >
                          {city}
                        </text>
                      </g>
                    );
                  })}
                </g>
              ) : (
                <g transform={`translate(${dimensions.width / 4}, ${0})`}>
                  {nodes.map((node, i) => {
                    let city = node.data.city;
                    if (city === "TOKYO JAPAN") city = "TOKYO";
                    const fontSize = fontScale(node.data.count);
                    return (
                      <g key={i}>
                        <circle
                          style={{ fill: node.data.color }}
                          strokeWidth="2px"
                          stroke={node.data.color}
                          cx={node.x}
                          cy={node.y}
                          r={node.r ? node.r : 0}
                        ></circle>
                        <text
                          x={node.x - 10}
                          y={node.y + node.r + 20}
                          style={{
                            fill: node.data.color,
                            fontWeight: "bold",
                            fontFamily:
                              "ITCFranklinGothic,helvetica,sans-serif",
                            textTransform: "uppercase",
                            fontSize: fontSize,
                          }}
                        >
                          {city}
                        </text>
                      </g>
                    );
                  })}
                </g>
              )}
            </Chart>
          </div>
        </div>
      </ErrorBoundary>
    );
  } else return null;
}

export default withStyles(styles)(MapViz);
