import React from "react";
import withStyles from "react-jss";

const styles = {
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif"
  },
  tag: {
    backgroundColor: "#333",
    opacity: "70%",
    padding: "0px 8px",
    margin: "2px",
    fontSize: "10px",
    zIndex: 2,
    textDecoration: "none",
    color: "white",
    fontWeight: 500,
    display: "inline-block",
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif"
  },
};
function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}
const PhotoCaption = ({ tags, photoCredit, tagMap, classes }) => {
  if (tags && tagMap) {
    return (
      <>
        <div className={classes.tagsContainer}>
          {tags.map((tagItem, j) => {
            if (
              tagItem.includes("tags/city") ||
              tagItem.includes("tags/season")
            ) {
              const formattedTag = getKeyByValue(tagMap, tagItem);
              return (
                <div key={j}>
                  {formattedTag && (
                    <div className={classes.tag}>
                      {formattedTag.toUpperCase()}
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
          <div className={classes.tag}>{photoCredit}</div>
      </>
    );
  }
};

export default withStyles(styles)(PhotoCaption);