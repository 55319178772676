// filter by grouping
export const filterByGrouping = (arr, grouping) => {
  return arr.filter(
    (data) =>
      JSON.stringify(data).toLowerCase().indexOf(grouping.toLowerCase()) !== -1
  );
};

export const convertToSlug = (Text) => {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const duplicateDataAsCount = (arr) => {
  let tempArr = [];
  arr.sort();
  var current = null;
  var cnt = 0;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] != current) {
      if (cnt > 0) {
        let tempObj = {};
        tempObj.name = current
          .replace(/(fashion-tags\/color\/)/g, "")
          .replace(/(tags\/city\/)/g, "");
        tempObj.total = cnt;
        tempArr.push(tempObj);
      }
      current = arr[i];
      cnt = 1;
    } else {
      cnt++;
    }
  }
  if (cnt > 0) {
    let tempObj = {};
    tempObj.name = current
      .replace(/(fashion-tags\/color\/)/g, "")
      .replace(/(tags\/city\/)/g, "");
    tempObj.total = cnt;
    tempArr.push(tempObj);
  }
  return tempArr;
};
