import React, { useEffect, useState } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import arrowUp from "../../../assets/arrow-up.png";
import arrowDown from "../../../assets/arrow-down.png";
import withStyles from "react-jss";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const styles = {
  dropdownList: {
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    listStyle: "none",
    padding: "0px 0px 0px 16px",
    border: "1px solid black",
    marginTop: "-1px",
    maxHeight: "300px",
    overflowY: "scroll",
    position: "absolute",
    width: "100%",
    zIndex: 10,
    backgroundColor: "white",
    boxSizing: "border-box",
  },
  comboBoxContainer: {
    position: "relative",
  },
  input: {
    width: "100%",
    fontSize: "100%",
    height: "44px",
    boxSizing: "border-box",
    border: "1px solid black",
    paddingLeft: "26px",
    "&:focus": {
      outline: "none",
      border: "2px solid #A60505",
    },
  },
  label: {
    //fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "uppercase",
  },
  arrowButton: {
    position: "absolute",
    right: 12,
    height: "40px",
    background: "none",
    border: "none",
    cursor: "pointer",
    lineHeight: 1.1,
    "&:focus": {
      outline: "none",
    },
  },
  pillsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  tagPill: {
    backgroundColor: "#F5F5F5",
    borderRadius: "5px",
    fontSize: "15px",
    border: "1px solid transparent",
    padding: "1px 7px",
    margin: "0 8px 8px 0",
    "&:focus": {
      outline: "none",
      border: "1px solid #A60505",
    },
  },
  pillX: {
    padding: "0 0 0 15px",
    cursor: "pointer",
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    margin: "17px 5px 5px 0px",
    cursor: "pointer",
    lineHeight: 1,
  },
};
function DropdownMultipleCombobox({ classes, items, callback, label }) {
  const [inputValue, setInputValue] = useState("");
  const {
    getSelectedItemProps,
    getDropdownProps,
    setSelectedItems,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({ initialSelectedItems: [] });
  const getFilteredItems = () =>
    items.filter(
      (item) =>
        selectedItems.indexOf(item) < 0 &&
        item.toLowerCase().includes(inputValue.toLowerCase())
    );

  // sends the selected items back to parent component
  useDidMountEffect(() => {
    callback(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    items: getFilteredItems(),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: false, // keep the menu open after selection.
          };
        default:
          break;
      }
      return changes;
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue("");
            setSelectedItems([selectedItem]);
          }
          break;
        default:
          break;
      }
    },
  });
  return (
    <div>
      <ErrorBoundary>
        <div className={classes.comboBoxContainer}>
          <div>
            <div
              {...getComboboxProps()}
              style={{ position: "relative", marginTop: "10px" }} //TO DO: move all styles to classes
            >
              <input
                placeholder={label}
                className={classes.input}
                {...getInputProps(
                  getDropdownProps({ preventKeyAction: isOpen })
                )}
              />
              <button
                {...getToggleButtonProps()}
                aria-label={"toggle menu"}
                className={classes.arrowButton}
              >
                <img
                  src={isOpen ? arrowUp : arrowDown}
                  alt={isOpen ? "close dropdown" : "open dropdown"}
                />
              </button>
            </div>
          </div>
          <ul
            {...getMenuProps()}
            className={isOpen ? classes.dropdownList : undefined}
          >
            {isOpen &&
              getFilteredItems(items).map((item, index) => (
                <li
                  className={classes.listItem}
                  style={highlightedIndex === index ? { color: "#A60505" } : {}}
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {item}
                </li>
              ))}
          </ul>
        </div>
        <div className={classes.pillsContainer}>
          {selectedItems.map((selectedItem, index) => (
            <span
              className={classes.tagPill}
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              {selectedItem}
              <span
                className={classes.pillX}
                style={{ padding: "0 0 0 15px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
              >
                &#10005;
              </span>
            </span>
          ))}
        </div>
      </ErrorBoundary>
    </div>
  );
}
export default withStyles(styles)(DropdownMultipleCombobox);
