
// This version produces a mapping file with the format:
// { tag: 'long/name/of/tag',
//   anotherTag: 'long/name/of/tag'}

// const mapping = (csv) => {
//   console.log({csv})
//   var lines = csv.split("\n");

//   var result = {};

//   for (var i = 1; i < lines.length; i++) {
//     var currentline = lines[i].split(",");
//     result[currentline[0]] = currentline[1];
//   }
//  console.log({result})
//   return result; //JavaScript object
// };

const mapping = (input) => {
  let result = []
  for(var i=0; i < input.length; i++) {
    result[input[i].slug] = input[i].tagstring
  }
  return result;
};

//var csv is the CSV file with headers
const csvToJson = (csv) => {
  var lines = csv.split("\n");
  var result = [];

  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result; //JavaScript object
  //return JSON.stringify(result); //JSON
};

export { mapping, csvToJson};
