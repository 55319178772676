import React from "react";
import withStyles from "react-jss";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const styles = {
  title: {
    fontWeight: 400,
    fontFamily: "Savoy",
    fontSize: "40px",
    margin: "10px",
    color: "#333",
    lineHeight: "1.05em",
  },
};

function Title({ classes, children }) {
  return (
    <ErrorBoundary>
    <h1 className={classes.title}>
      {children}
    </h1>
    </ErrorBoundary>
  );
}

export default withStyles(styles)(Title);
