import React, { useState, useEffect } from "react";
import withStyles from "react-jss";
import { Skeleton } from "@material-ui/lab";
import PhotoCaption from "./PhotoCaption";
import leftArrow from "../../assets/arrow-left.svg";
import rightArrow from "../../assets/arrow-right.svg";
import Masonry from "react-masonry-css";
import classnames from "classnames";
import styles from "./styles";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { isMobile } from "react-device-detect";
// Note: styles are in a separate file

function GalleryComponent({
  classes,
  photos,
  columnCount = 4,
  tagMap,
  status,
  searchTerms,
  apiLoaded,
  sizeApi,
  pageApi,
  setSizeApi,
  setPageApi,
  loadBtn,
}) {
  const [noOfImages, setNoOfmIages] = useState(21);
  const [mouseOverModalImage, setMouseOverModalImage] = useState(false);
  const [mouseOverNav, setMouseOverNav] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [counter, setCounter] = useState(0);
  const [fourthClick, setFourthClick] = useState(false);
  //Adding nav counter for Ads
  const [obj, setObj] = useState({
    imgSource: "",
    showModal: false,
    currentIndex: 0,
    tag: [],
    navCounter: 0,
  });
  const [images, setImages] = useState(photos);

  const breakpointColumnsObj = {
    default: columnCount,
    400: 3,
  };

  const items = images.map((item, i) => {
    return (
      <div
        className={classnames(
          i % columnCount === 0
            ? classes.imageLeft
            : i % 3 === columnCount - 1
            ? classes.imageRight
            : classes.image,
          classes.item
        )}
        key={i}
        onMouseEnter={() => {
          setHoveredImage(i);
          if (!isMobile) setHoveredImage(i);
          else setHoveredImage(null);
        }}
        onMouseLeave={() => {
          setHoveredImage(null);
        }}
      >
        <img
          className={classes.imageContainer}
          src={item.imageUrlHover}
          alt={"thumbnail"}
          onClick={() => {
            if (window.parent && window.parent.dataLayer) {
              window.parent.dataLayer.push({
                event: `datavisualization-vogueStreetStyle-${item.imageUrlMaster}-click`,
              });
            }
            setObj({
              ...obj,
              currentIndex: i,
              imgSource: item.imageUrlMaster,
              showModal: true,
              tag: item.tags,
              photo_credit: item.photo_credit,
            });
          }}
        />
        <div className={classes.tagHolderThumbnail}>
          {i === hoveredImage ? (
            <ErrorBoundary>
              <PhotoCaption
                tags={item.tags}
                tagMap={tagMap}
                photoCredit={item.photo_credit}
              />
            </ErrorBoundary>
          ) : null}
        </div>
      </div>
    );
  });

  // If pressed key is our target key then set to true
  const downHandler = ({ key }) => {
    if (key === "ArrowLeft" && obj.showModal === true) {
      setCounter(counter + 1);
      setFourthClick((counter + 1) % 4 === 0);
      if (photos.length && photos[obj.currentIndex - 1]) {
        setObj({
          ...obj,
          currentIndex: !fourthClick ? obj.currentIndex - 1 : obj.currentIndex,
          imgSource:
            photos[!fourthClick ? obj.currentIndex - 1 : obj.currentIndex]
              .imageUrlMaster,
          showModal: true,
          tag:
            photos[!fourthClick ? obj.currentIndex - 1 : obj.currentIndex].tags,
          photo_credit:
            photos[!fourthClick ? obj.currentIndex - 1 : obj.currentIndex]
              .photo_credit,
          navCounter: counter + 1,
        });
      }
    } else if (key === "ArrowRight" && obj.showModal === true) {
      setCounter(counter + 1);
      setFourthClick((counter + 1) % 4 === 0);
      if (photos.length && photos[obj.currentIndex + 1]) {
        setObj({
          ...obj,
          currentIndex: !fourthClick ? obj.currentIndex + 1 : obj.currentIndex,
          imgSource:
            photos[!fourthClick ? obj.currentIndex + 1 : obj.currentIndex]
              .imageUrlMaster,
          showModal: true,
          tag:
            photos[!fourthClick ? obj.currentIndex + 1 : obj.currentIndex].tags,
          navCounter: counter + 1,
          photo_credit:
            photos[!fourthClick ? obj.currentIndex + 1 : obj.currentIndex]
              .photo_credit,
        });
      }
    } else if (key === "Escape" && obj.showModal === true) {
      if (isMobile)
        setObj({
          ...obj,
          imgSource: "",
          showModal: false,
          currentIndex: 0,
          tag: [],
          photo_credit: "",
          navCounter: 0,
        });
      else
        setObj({
          ...obj,
          showModal: false,
          navCounter: 0,
        });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [obj]);

  useEffect(() => {
    // if (photos.length > noOfImages) {
    //   const tempImageArray = photos.slice(0, noOfImages);
    //   setImages(tempImageArray);
    // } else {
    //   setImages(photos);
    // }
    setImages(photos);
  }, [noOfImages, photos]);

  useEffect(() => {
    if (window.parent && window.parent.cnBus) {
      window.parent.cnBus.emit("react.ready");
    }
  }, [obj.navCounter % 4 === 0 && obj.navCounter !== 0]);

  // get search terms to use in string for empty state logic
  const arrayOfSearchTerms = [];
  Object.keys(searchTerms).forEach((section) => {
    for (let i = 0; i < searchTerms[section].length; i++)
      arrayOfSearchTerms.push([searchTerms[section][i]]);
  });

  const itemTags = searchTerms.style.concat(searchTerms.item);

  const noStyles = itemTags.toString().toLowerCase().replace(/,/g, " and ");

  const noResults = arrayOfSearchTerms.length > 0 && photos.length === 0;

  return (
    <div className={classes.container}>
      {apiLoaded && noResults ? (
        <div className={classes.noResultsContainer}>
          <div className={classes.noResultsText}>
            There are no photos {itemTags.length > 0 ? `with` : null}
            <span className={classes.tagText}> {noStyles} </span>
            {searchTerms.city.length > 0 ? ` in ` : null}
            {searchTerms.city.length > 0 ? (
              <span className={classes.tagText}> {searchTerms.city}</span>
            ) : null}
            {searchTerms.season.length > 0 ? ` for ` : null}
            {searchTerms.season.length > 0 ? (
              <span className={classes.tagText}>{searchTerms.season}</span>
            ) : null}
            {searchTerms.season.length === 0 ? ` yet` : null}.
          </div>
        </div>
      ) : apiLoaded ? (
        <p className={classes.galleryHeader}>All Photos</p>
      ) : apiLoaded && photos.length > 0 ? (
        <p className={classes.galleryHeader}>{`${photos.length} Photos`}</p>
      ) : (
        <p className={classes.galleryHeader}>loading photos ... </p>
      )}
      {/* <Results
        noResults={noResults}
        searchTerms={searchTerms}
        noStyles={noStyles}
        itemTags={itemTags}
        photoLength={photos.length}
        status={status}
      /> */}

      {apiLoaded && photos.length > 0 ? (
        <>
          <ErrorBoundary>
            <div
              className={classes.modal}
              tabIndex={"0"}
              style={{
                display: obj.showModal ? "flex" : "none",
                flexDirection: "column",
              }}
              onClick={() => {
                if (!mouseOverModalImage & !mouseOverNav) {
                  if (isMobile) {
                    setObj({
                      ...obj,
                      imgSource: "",
                      showModal: false,
                      currentIndex: 0,
                      tag: [],
                      photo_credit: "",
                      navCounter: 0,
                    });
                    setCounter(0);
                  } else {
                    setObj({
                      ...obj,
                      showModal: false,
                      navCounter: 0,
                    });
                    setCounter(0);
                  }
                }
              }}
            >
              <span
                className={classnames(
                  isMobile ? classes.closeMobile : classes.closeDesktop,
                  classes.close
                )}
                onClick={() => {
                  if (isMobile) {
                    setObj({
                      ...obj,
                      imgSource: "",
                      showModal: false,
                      currentIndex: 0,
                      tag: [],
                      photo_credit: "",
                      navCounter: 0,
                    });
                    setCounter(0);
                  } else {
                    setObj({
                      ...obj,
                      showModal: false,
                      navCounter: 0,
                    });
                    setCounter(0);
                  }
                }}
              >
                &times;
              </span>
              <div className={classes.fullsizeImageContainer}>
                {obj.navCounter === 0 || obj.navCounter % 4 !== 0 ? (
                  <img
                    src={obj.imgSource}
                    alt={"modal-img"}
                    className={classes.modalcontent}
                    onMouseEnter={() => {
                      setMouseOverModalImage(true);
                    }}
                    onMouseLeave={() => {
                      setMouseOverModalImage(false);
                    }}
                  ></img>
                ) : (
                  <div
                    className={"ad__slot--rail"}
                    onMouseEnter={() => {
                      setMouseOverModalImage(true);
                    }}
                    onMouseLeave={() => {
                      setMouseOverModalImage(false);
                    }}
                  ></div>
                )}

                <div>
                  {obj.currentIndex && (
                    <span
                      className={classes.navPrev}
                      onClick={() => {
                        setCounter(counter + 1);
                        setFourthClick((counter + 1) % 4 === 0);
                        if (window.parent && window.parent.dataLayer) {
                          window.parent.dataLayer.push({
                            event: `datavisualization-vogueStreetStyle-galleryArrowLeft-click`,
                          });
                        }
                        if (photos.length && photos[obj.currentIndex - 1]) {
                          setObj({
                            ...obj,
                            currentIndex: !fourthClick
                              ? obj.currentIndex - 1
                              : obj.currentIndex,
                            imgSource:
                              photos[
                                !fourthClick
                                  ? obj.currentIndex - 1
                                  : obj.currentIndex
                              ].imageUrlMaster,
                            showModal: true,
                            tag:
                              photos[
                                !fourthClick
                                  ? obj.currentIndex - 1
                                  : obj.currentIndex
                              ].tags,
                            photo_credit:
                              photos[
                                !fourthClick
                                  ? obj.currentIndex - 1
                                  : obj.currentIndex
                              ].photo_credit,
                            navCounter: counter + 1,
                          });
                        }
                      }}
                      onMouseEnter={() => {
                        setMouseOverNav(true);
                      }}
                      onMouseLeave={() => {
                        setMouseOverNav(false);
                      }}
                    >
                      <img
                        className={classes.navImage}
                        alt={"left-nav"}
                        src={leftArrow}
                      ></img>
                    </span>
                  )}
                  {obj.currentIndex !== photos.length - 1 && (
                    <span
                      className={classes.navNext}
                      onClick={() => {
                        setCounter(counter + 1);
                        setFourthClick((counter + 1) % 4 === 0);
                        if (window.parent && window.parent.dataLayer) {
                          window.parent.dataLayer.push({
                            event: `datavisualization-vogueStreetStyle-galleryArrowRight-click`,
                          });
                        }
                        if (photos.length && photos[obj.currentIndex + 1]) {
                          setObj({
                            ...obj,
                            currentIndex: !fourthClick
                              ? obj.currentIndex + 1
                              : obj.currentIndex,
                            imgSource:
                              photos[
                                !fourthClick
                                  ? obj.currentIndex + 1
                                  : obj.currentIndex
                              ].imageUrlMaster,
                            showModal: true,
                            tag:
                              photos[
                                !fourthClick
                                  ? obj.currentIndex + 1
                                  : obj.currentIndex
                              ].tags,
                            navCounter: counter + 1,
                            photo_credit:
                              photos[
                                !fourthClick
                                  ? obj.currentIndex + 1
                                  : obj.currentIndex
                              ].photo_credit,
                          });
                        }
                      }}
                      onMouseEnter={() => {
                        setMouseOverNav(true);
                      }}
                      onMouseLeave={() => {
                        setMouseOverNav(false);
                      }}
                    >
                      <img
                        className={classes.navImage}
                        alt={"right-nav"}
                        src={rightArrow}
                      ></img>
                    </span>
                  )}
                </div>
                <ErrorBoundary>
                  {!fourthClick && (
                    <PhotoCaption
                      tags={obj.tag}
                      tagMap={tagMap}
                      photoCredit={obj.photo_credit}
                    />
                  )}
                </ErrorBoundary>
              </div>
            </div>
            <ErrorBoundary>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className={classes.masonryGrid}
                columnClassName={classes.masonryGrid_column}
              >
                {items}
              </Masonry>
            </ErrorBoundary>
            <ErrorBoundary>
              {photos.length &&
                (photos.length === sizeApi * pageApi || !loadBtn) && (
                  <div
                    className={
                      loadBtn ? classes.loadButton : classes.loadButtonActive
                    }
                    onClick={() => {
                      if (window.parent && window.parent.dataLayer) {
                        window.parent.dataLayer.push({
                          event: `datavisualization-vogueStreetStyle-showMoreButton-click`,
                        });
                      }
                      //setNoOfmIages(noOfImages + 21);

                      setPageApi(pageApi + 1);
                    }}
                  >
                    {loadBtn && <div className="">LOAD MORE </div>}
                    {!loadBtn && <div className="loader"></div>}
                  </div>
                )}
            </ErrorBoundary>
          </ErrorBoundary>
        </>
      ) : apiLoaded && photos.length === 0 && arrayOfSearchTerms.length > 0 ? (
        ""
      ) : (
        <div className={classes.galleryContainer}>
          <Skeleton
            variant="rect"
            width={200}
            height={300}
            className={classes.image}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={300}
            className={classes.image}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={300}
            className={classes.image}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={300}
            className={classes.image}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={300}
            className={classes.image}
          />
          <Skeleton
            variant="rect"
            width={200}
            height={300}
            className={classes.image}
          />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(GalleryComponent);
