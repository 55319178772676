import React from "react";
import withStyles from "react-jss";
import { useChartDimensions } from "./utils";
import Chart from "./Chart";
import * as d3 from "d3";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const styles = {
  container: {
    height: "350px",
    "@media (max-width: 820px)": { maxWidth: "300px" },
  },
  vizTitle: {
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    color: "#333",
    textTransform: "uppercase",
  },
};

function ColorViz({ classes, mapColor }) {
  var colors = mapColor.map((item, i) => {
    return { color: item.name, count: item.total };
  });

  colors.sort((a, b) => b.count - a.count);

  // top 10 colors
  var topColors = [...colors].slice(0, 10);

  // color mapping points to linearGradients in svg defs
  const colorMap = {
    red: "url(#red)",
    orange: "url(#orange)",
    yellow: "url(#yellow)",
    green: "url(#green)",
    blue: "url(#blue)",
    purple: "url(#purple)",
    pink: "url(#pink)",
    white: "#FFFFFF",
    gray: "url(#gray)",
    black: "url(#black)",
    brown: "url(#brown)",
    beige: "url(#beige)",
    neon: "url(#neon)",
    metallic: "url(#metallic)",
    mulitcolor: "url(#multicolor)",
    multicolor: "url(#multicolor",
    iridescent: "url(#iridescent)",
    transparent: "url(#transparent)",
  };

  // set up chart dimensions
  const [ref, dimensions] = useChartDimensions({
    marginTop: 0,
    marginLeft: 80,
    marginRight: 0,
    height: 260,
  });

  // create object with calculated values for positioning rects within total height
  const total = d3.sum(topColors, (d) => d.count / 260);
  let value = 0;
  const stack = topColors.map((d) => {
    return {
      color: d.color,
      count: d.count / total,
      startValue: value / total,
      endValue: (value += d.count) / total,
    };
  });

  return (
    <ErrorBoundary>
      <div className={classes.container} ref={ref}>
        {topColors.length > 0 ? (
          <p className={classes.vizTitle}>
            Top Tagged Color: {topColors[0].color}
          </p>
        ) : null}
        <div>
          <Chart dimensions={dimensions}>
            <defs>
              <linearGradient id="red">
                {/* <stop offset="0" stopColor="#FF9F9F" /> */}
                <stop offset=".2" stopColor="#FF9F9F" />
                {/* <stop offset=".2001" stopColor="#FB718A" /> */}
                <stop offset=".4" stopColor="#FB718A" />
                {/* <stop offset=".4001" stopColor="#EA5353" /> */}
                <stop offset=".6" stopColor="#EA5353" />
                {/* <stop offset=".6001" stopColor="#AA3939" /> */}
                <stop offset=".8" stopColor="#AA3939" />
                {/* <stop offset=".8001" stopColor="#8B1212" /> */}
                <stop offset="1" stopColor="#8B1212" />
              </linearGradient>
              <linearGradient id="orange">
                {/* <stop offset="0" stopColor="#FDDBBF" /> */}
                <stop offset=".2" stopColor="#FDDBBF" />
                {/* <stop offset=".2001" stopColor="#F8CE8F" /> */}
                <stop offset=".4" stopColor="#F8CE8F" />
                {/* <stop offset=".4001" stopColor="#FFA65C" /> */}
                <stop offset=".6" stopColor="#FFA65C" />
                {/* <stop offset=".6001" stopColor="#F48C35" /> */}
                <stop offset=".8" stopColor="#F48C35" />
                {/* <stop offset=".8001" stopColor="#E66F0C" /> */}
                <stop offset="1" stopColor="#E66F0C" />
              </linearGradient>
              <linearGradient id="yellow">
                {/* <stop offset="0" stopColor="#FFEFB6" /> */}
                <stop offset=".2" stopColor="#FFEFB6" />
                {/* <stop offset=".2001" stopColor="#FFE59B" /> */}
                <stop offset=".4" stopColor="#FFE59B" />
                {/* <stop offset=".4001" stopColor="#FEDC48" /> */}
                <stop offset=".6" stopColor="#FEDC48" />
                {/* <stop offset=".6001" stopColor="#F3BD34" /> */}
                <stop offset=".8" stopColor="#F3BD34" />
                {/* <stop offset=".8001" stopColor="#DDA700" /> */}
                <stop offset="1" stopColor="#DDA700" />
              </linearGradient>
              <linearGradient id="green">
                {/* <stop offset="0" stopColor="#D6F3D7" /> */}
                <stop offset=".2" stopColor="#D6F3D7" />
                {/* <stop offset=".2001" stopColor="#AAEA93" /> */}
                <stop offset=".4" stopColor="#AAEA93" />
                {/* <stop offset=".4001" stopColor="#69CC7E" /> */}
                <stop offset=".6" stopColor="#69CC7E" />
                {/* <stop offset=".6001" stopColor="#30A143" /> */}
                <stop offset=".8" stopColor="#30A143" />
                {/* <stop offset=".8001" stopColor="#2A6325" /> */}
                <stop offset="1" stopColor="#2A6325" />
              </linearGradient>
              <linearGradient id="blue">
                {/* <stop offset="0" stopColor="#A7D2DF" /> */}
                <stop offset=".2" stopColor="#A7D2DF" />
                {/* <stop offset=".2001" stopColor="#65C4E0" /> */}
                <stop offset=".4" stopColor="#65C4E0" />
                {/* <stop offset=".4001" stopColor="#298BC8" /> */}
                <stop offset=".6" stopColor="#298BC8" />
                {/* <stop offset=".6001" stopColor="#1164B0" /> */}
                <stop offset=".8" stopColor="#1164B0" />
                {/* <stop offset=".8001" stopColor="#053D8B" /> */}
                <stop offset="1" stopColor="#053D8B" />
              </linearGradient>
              <linearGradient id="purple">
                {/* <stop offset="0" stopColor="#E7DAEE" /> */}
                <stop offset=".2" stopColor="#E7DAEE" />
                {/* <stop offset=".2001" stopColor="#DFB0E7" /> */}
                <stop offset=".4" stopColor="#DFB0E7" />
                {/* <stop offset=".4001" stopColor="#B874D0" /> */}
                <stop offset=".6" stopColor="#B874D0" />
                {/* <stop offset=".6001" stopColor="#904DB0" /> */}
                <stop offset=".8" stopColor="#904DB0" />
                {/* <stop offset=".8001" stopColor="#570079" /> */}
                <stop offset="1" stopColor="#570079" />
              </linearGradient>
              <linearGradient id="pink">
                {/* <stop offset="0" stopColor="#F2DCE7" /> */}
                <stop offset=".2" stopColor="#F2DCE7" />
                {/* <stop offset=".2001" stopColor="#F4B2C6" /> */}
                <stop offset=".4" stopColor="#F4B2C6" />
                {/* <stop offset=".4001" stopColor="#EA6394" /> */}
                <stop offset=".6" stopColor="#EA6394" />
                {/* <stop offset=".6001" stopColor="#CB347C" /> */}
                <stop offset=".8" stopColor="#CB347C" />
                {/* <stop offset=".8001" stopColor="#A00F62" /> */}
                <stop offset="1" stopColor="#A00F62" />
              </linearGradient>
              <linearGradient id="black">
                {/* <stop offset="0" stopColor="#494949" /> */}
                <stop offset=".2" stopColor="#494949" />
                {/* <stop offset=".2001" stopColor="#3A3434" /> */}
                <stop offset=".4" stopColor="#3A3434" />
                {/* <stop offset=".4001" stopColor="#2B2828" /> */}
                <stop offset=".6" stopColor="#2B2828" />
                {/* <stop offset=".6001" stopColor="#131131" /> */}
                <stop offset=".8" stopColor="#131131" />
                {/* <stop offset=".8001" stopColor="#000000" /> */}
                <stop offset="1" stopColor="#000000" />
              </linearGradient>
              <linearGradient id="gray">
                {/* <stop offset="0" stopColor="#E9E9E9" /> */}
                <stop offset=".2" stopColor="#E9E9E9" />
                {/* <stop offset=".2001" stopColor="#D3D3D3" /> */}
                <stop offset=".4" stopColor="#D3D3D3" />
                {/* <stop offset=".4001" stopColor="#C7C7C7" /> */}
                <stop offset=".6" stopColor="#C7C7C7" />
                {/* <stop offset=".6001" stopColor="#B7C1C7" /> */}
                <stop offset=".8" stopColor="#B7C1C7" />
                {/* <stop offset=".8001" stopColor="#ABB6BD" /> */}
                <stop offset="1" stopColor="#ABB6BD" />
              </linearGradient>
              <linearGradient id="brown">
                {/* <stop offset="0" stopColor="#B08559" /> */}
                <stop offset=".2" stopColor="#B08559" />
                {/* <stop offset=".2001" stopColor="#9D5D1E" /> */}
                <stop offset=".4" stopColor="#9D5D1E" />
                {/* <stop offset=".4001" stopColor="#7A3006" /> */}
                <stop offset=".6" stopColor="#7A3006" />
                {/* <stop offset=".6001" stopColor="#5B1208" /> */}
                <stop offset=".8" stopColor="#5B1208" />
                {/* <stop offset=".8001" stopColor="#3F0400" /> */}
                <stop offset="1" stopColor="#3F0400" />
              </linearGradient>
              <linearGradient id="beige">
                {/* <stop offset="0" stopColor="#F9E7D6" /> */}
                <stop offset=".2" stopColor="#F9E7D6" />
                {/* <stop offset=".2001" stopColor="#EEDCCE" /> */}
                <stop offset=".4" stopColor="#EEDCCE" />
                {/* <stop offset=".4001" stopColor="#E1C9AB" /> */}
                <stop offset=".6" stopColor="#E1C9AB" />
                {/* <stop offset=".6001" stopColor="#D0BAA1" /> */}
                <stop offset=".8" stopColor="#D0BAA1" />
                {/* <stop offset=".8001" stopColor="#C8A87F" /> */}
                <stop offset="1" stopColor="#C8A87F" />
              </linearGradient>
              <linearGradient id="neon">
                {/* <stop offset="0" stopColor="#BAFF75" /> */}
                <stop offset=".2" stopColor="#BAFF75" />
                {/* <stop offset=".2001" stopColor="#FFB800" /> */}
                <stop offset=".4" stopColor="#FFB800" />
                {/* <stop offset=".4001" stopColor="#FF00C7" /> */}
                <stop offset=".6" stopColor="#FF00C7" />
                {/* <stop offset=".6001" stopColor="#00FFF0" /> */}
                <stop offset=".8" stopColor="#00FFF0" />
                {/* <stop offset=".8001" stopColor="#FBFF34" /> */}
                <stop offset="1" stopColor="#FBFF34" />
              </linearGradient>
              <linearGradient id="multicolor">
                <stop offset="0" stopColor="#FF88C1" />
                <stop offset=".2" stopColor="#FF88C1" />
                <stop offset=".2001" stopColor="#5FB2FF" />
                <stop offset=".4" stopColor="#5FB2FF" />
                <stop offset=".4001" stopColor="#46C987" />
                <stop offset=".6" stopColor="#46C987" />
                <stop offset=".6001" stopColor="#FF9F9F" />
                <stop offset=".8" stopColor="#FF9F9F" />
                <stop offset=".8001" stopColor="#9F0000" />
                <stop offset="1" stopColor="#9F0000" />
              </linearGradient>
              <linearGradient id="iridescent">
                <stop offset="0%" stopColor="#FFEEEE" />
                <stop offset="37%" stopColor="#F6FAFF" />
                <stop offset="42%" stopColor="#E8F1FF" />
                <stop offset="52%" stopColor="#F1F7FF" />
                <stop offset="57%" stopColor="#E7F1FF" />
                <stop offset="69%" stopColor="#FEF1F2" />
                <stop offset="80%" stopColor="#FEFEFE" />
                <stop offset="90%" stopColor="#F3FFDC" />
                <stop offset="95%" stopColor="#F8FFEA" />
                <stop offset="100%" stopColor="#F2F6EA" />
              </linearGradient>
              <linearGradient id="metallic">
                <stop offset="5%" stopColor="#EFE7BB" />
                <stop offset="20%" stopColor="#fff1eb" />
                <stop offset="50%" stopColor="#EEF2F3" />
                <stop offset="80%" stopColor="#D4D3DD" />
                <stop offset="99%" stopColor="#D9E5EA" />
              </linearGradient>
              <linearGradient id="transparent">
                <stop offset="5%" stopColor="#ECE9E6" />
                <stop offset="95%" stopColor="#FFF" />
              </linearGradient>
            </defs>
            {stack.map((d, i) => {
              return (
                <g key={i}>
                  {/* COLOR LABELS */}
                  {d.endValue - d.startValue > 9 ? (
                    <text
                      x={-8}
                      y={d.startValue + (d.endValue - d.startValue) / 2.5}
                      style={{
                        fill: "#808080",
                        textAnchor: "end",
                        fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        marginTop: "10px",
                      }}
                    >
                      {d.color}
                    </text>
                  ) : null}
                  {/* COLOR RECTANGLES */}
                  {d.endValue - d.startValue > 9 ? (
                    <rect
                      width={dimensions.width - dimensions.marginLeft}
                      height={
                        d.endValue - d.startValue - 5 > 0
                          ? d.endValue - d.startValue - 5
                          : d.endValue - d.startValue
                      }
                      fill={`${colorMap[d.color]}`}
                      stroke={d.color === "white" ? "#E1E1E1" : null}
                      y={d.startValue}
                      x={0}
                    ></rect>
                  ) : null}
                </g>
              );
            })}
          </Chart>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default withStyles(styles)(ColorViz);
