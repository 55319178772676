const styles = {
  masonryGrid: {
    display: "flex",
    marginLeft: "-10px" /* gutter size offset */,
    width: "auto",
  },
  masonryGrid_column: {
    paddingLeft: "10px" /* gutter size */,
    backgroundClip: "padding-box",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  galleryContainer: {
    display: "flex",
    flexWrap: "wrap",
    "&::before": { content: "", flexBasis: "100%", width: 0, order: 2 },
    "&::after": { content: "", flexBasis: "100%", width: 0, order: 2 },
  },
  galleryHeader: {
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "uppercase",
    width: "100%",
  },
  noResultsContainer: {
    border: "1px solid #000000",
    display: "flex",
    flex: "1 1 100%",
    margin: "20px auto",
    height: "250px",
  },
  noResultsText: {
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    fontSize: "18px",
    fontWeight: 400,
    margin: "0 auto",
    padding: "11%",
    alignSelf: "center",
  },
  image: { flex: "0 1 30%", margin: "5px" },
  tagsContainer: {
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    display: "flex",
    flexWrap: "wrap",
  },
  imageHolder: {
    padding: "2px",
  },
  zoomImage: {
    maxWidth: "110%",
    maxHeight: "110%",
    position: "relative",
  },
  box: {
    display: "grid",
  },
  masonry: {
    padding: "0",
    columnGap: "4px",
    rowGap: "6px",
    fontSize: ".85em",
  },
  tagHolder: {
    position: "absolute",
    bottom: 20,
    left: 5,
    zIndex: 4,
    display: "flex",
    margin: "auto",
    padding: "10px",
    maxWidth: "100%",
    overflowX: "scroll",
  },
  tagThumbnail: {
    backgroundColor: "#333",
    opacity: "50%",
    padding: "2px 4px",
    margin: "2px",
    fontSize: "10px",
    zIndex: 2,
    textDecoration: "none",
    color: "white",
    fontWeight: 500,
    lineHeight: "1em !important",
  },
  modal: {
    display: "none" /* Hidden by default */,
    position: "fixed" /* Stay in place */,
    zIndex: 20 /* Sit on top */,
    padding: "100px 0px 10px 0px" /*Location of the box */,
    left: 0,
    top: 0,
    width: "100%" /* Full width */,
    height: "100%" /* Full height */,
    maxHeight: "100%",
    overflow: "auto" /* Enable scroll if needed */,
    backgroundColor: "rgba(0, 0, 0, 0.9)" /* Black w/ opacity */,
  },
  modalcontent: {
    margin: "auto",
    width: "auto",
    maxWidth: "104%",
    maxHeight: "90%",
  },
  item: {
    cursor: "pointer",
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    width: "100%",
    transition: "0.5s ease all",
    boxSizing: "border-box",
    position: "relative",
    "@media screen and (min-width: 400px)": {
      "&:hover": {
        transform: `scale(1.2) translate(0px, 30px)`,
        zIndex: 10,
      },
    },
  },
  tag: {
    display: "none",
  },
  tagText: {
    zIndex: 2,
    textDecoration: "none",
    color: "#A60505",
    fontWeight: 600,
  },
  imageContainer: {
    maxWidth: "100%",
    maxHeight: "100%",
    position: "relative",
  },
  imageRight: {
    composes: "$image",
    "@media screen and (min-width: 400px)": {
      "&:hover": {
        transform: `scale(1.2) translate(-25px, 30px)`,
      },
    },
  },
  imageLeft: {
    composes: "$image",
    "@media screen and (min-width: 400px)": {
      "&:hover": {
        transform: `scale(1.2) translate(35px, 30px)`,
      },
    },
  },
  tagHolderThumbnail: {
    position: "absolute",
    bottom: "3px",
    left: 0,
    zIndex: 4,
    margin: "auto",
    maxWidth: "100%",
    overflowX: "scroll",
    lineHeight: "1em !important",
  },
  close: {
    position: "absolute",
    color: " #f1f1f1",
    fontSize: "40px",
    fontWeight: "bold",
    transition: "0.3s",
    cursor: "pointer",
    textShadow: "1px 1px 5px black",
    "&:hover": {
      color: " #bbb",
      textDecoration: "none",
      cursor: "pointer",
    },
    "&:focus": {
      color: " #bbb",
      textDecoration: "none",
      cursor: " pointer",
    },
  },
  closeDesktop: {
    top: "125px",
    right: "35px",
  },
  closeMobile: {
    top: "75px",
    right: "25px",
  },
  navPrev: {
    zIndex: 1,
    position: "absolute",
    top: "40%",
    left: "2%",
    padding: "10px",
    cursor: "pointer",
  },
  navNext: {
    zIndex: 1,
    position: "absolute",
    top: "40%",
    right: "2%",
    padding: "10px",
    cursor: "pointer",
  },
  fullsizeImageContainer: {
    height: "90%",
    "@media (max-width: 580px)": { height: "auto" },
    margin: "auto",
  },
  navImage: {
    height: "60px",
    width: "40px",
  },
  loadButton: {
    border: "2px solid #a60505",
    color: "#a60505",
    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    padding: "1rem",
    textAlign: "center",
    letterSpacing: "0.143em",
    lineHeight: "1.38em",
    fontSize: "13px",
    fontWeight: 500,
    minWidth: "100px",
    margin: "25px auto 0",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
    "&:hover": {
      color: "rgb(255, 255, 255)",
      backgroundColor: "rgb(166, 5, 5)",
      transitionProperty: "color, background-color",
      transitionDuration: "200ms",
      transitionTimingFunction: "ease-in",
    },
  },
  loadButtonActive: {
    border: "2px solid #a60505",

    fontFamily: "ITCFranklinGothic,helvetica,sans-serif",
    padding: "1rem",
    textAlign: "center",
    letterSpacing: "0.143em",
    lineHeight: "1.38em",
    fontSize: "13px",
    fontWeight: 500,
    minWidth: "100px",
    margin: "25px auto 0",
    cursor: "pointer",
    pointerEvents: "none",
    justifyContent: "center",
    display: "flex",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(166, 5, 5)",
    transitionProperty: "color, background-color",
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in",
  },
};

export default styles;
