import React, { createContext, useContext } from "react";
import { dimensionsPropsType } from "./utils";
import withStyles from "react-jss";

const styles = {
  chart: {
    overflow: "visible !important",
    display: "block",
    margin: "0 auto",
  },
};

const ChartContext = createContext();
export const useChartDimensions = () => useContext(ChartContext);

const Chart = ({ dimensions, children, classes }) => (
  <ChartContext.Provider value={dimensions}>
    <svg
      className={classes.chart}
      width={dimensions.width}
      height={dimensions.height}
    >
      <g
        transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
      >
        {children}
      </g>
    </svg>
  </ChartContext.Provider>
);

Chart.propTypes = {
  dimensions: dimensionsPropsType,
};

Chart.defaultProps = {
  dimensions: {},
};

export default withStyles(styles)(Chart);
